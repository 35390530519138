/* eslint-disable react-hooks/exhaustive-deps */
import style from "./style.module.css";
import { Timeline } from "rsuite";
// import 'rsuite/dist/rsuite.min.css';
import "rsuite/dist/rsuite-no-reset.min.css";
import Location from "../../../assets/successStory/location.svg";
import Flag from "../../../assets/successStory/flag.svg";
import Rectangle from "../../../assets/successStory/rectangle.svg";
import StoryPath from "./StoryPath";
import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";

const Story = ({ story, selectedStory, resetLanding }) => {
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState([]);
  const [mediaCount, setMediaCount] = useState(1);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    setCount(1);
    setTotal([]);
    let newArray = [];
    for (let i = 0; i < story.events.length; i += 4) {
      newArray.push(story.events.slice(i, i + 4));
    }
    setTotal(newArray);

    setMediaCount(1);

    let filteredVideo = [];
    // eslint-disable-next-line
    story.video.map((el) => {
      if (el !== null) {
        filteredVideo.push({ video: el });
      }
    });
    // eslint-disable-next-line
    var filtered = story.picture.filter((el) => {
      if (el !== null) {
        return { picture: el };
      }
    });
    filteredVideo = filteredVideo.concat(filtered);
    setMedia(filteredVideo);
  }, [story]);

  return (
    <div className={style.mainContainer}>
      <div className={style.backBtn} onClick={() => resetLanding()}>
        <FaArrowLeftLong className={style.leftIcon} />
        <p className={style.backText}>Back</p>
      </div>
      <div className={style.container}>
        <div
          className={style.storyContent1}
          onClick={() => selectedStory(story)}
        >
          <div className={style.profile}>
            {story.profile_picture && (
              <img
                className={style.profilePicture}
                src={story.profile_picture}
                alt="profile_picture"
              />
            )}
            <div className={style.profileDetails}>
              <p className={style.employeeName}>{story.employee_name}</p>
              <p className={style.tenure}>Tenure: {story.tenure}</p>
            </div>
          </div>

          <hr className={style.divider} />

          <Timeline className="custom-timeline">
            <Timeline.Item dot={<img src={Location} alt="Location" />}>
              <p className={style.eventName}>{story.first_role.event_name}</p>
              <p className={style.function}>{story.first_role.function}</p>
              <p className={style.site}>{story.first_role.site}</p>
            </Timeline.Item>
            <Timeline.Item dot={<img src={Flag} alt="Flag" />}>
              <p className={style.eventName}>{story.current_role.event_name}</p>
              <p className={style.function}>{story.current_role.function}</p>
              <p className={style.site}>{story.current_role.site}</p>
            </Timeline.Item>
          </Timeline>

          {story.quote !== "EMPTY" ? (
            <div>
              <img
                className={style.rectangleImg}
                src={Rectangle}
                alt="Rectangle"
              />
              <blockquote className={style.quote}>
                {story.quote}
              </blockquote>
            </div>
          ) : (
            <div className={style.noQuote}></div>
          )}
        </div>

        {/* video part */}
        <div className={style.storyContent2}>
          {media.length > 0 && media[mediaCount - 1].video !== undefined ? (
            <video
              className={style.video}
              src={media[mediaCount - 1].video}
              width={100}
              height={100}
              preload="auto"
              controls
              controlsList="nodownload"
            />
          ) : (
            media.length > 0 &&
            media[mediaCount - 1] !== null && (
              <img
                className={style.mediaImage}
                src={media[mediaCount - 1]}
                alt="profile_images"
              />
            )
          )}

          <div className={style.bottomPart}>
            <div className={style.bottomCircles}>
              {media &&
                media.length > 0 &&
                media.map((obj, index) => {
                  return (
                    <hr key={index}
                      className={
                        index + 1 !== mediaCount
                          ? style.circle
                          : style.activeCircle
                      }
                    />
                  );
                })}
            </div>

            <div className={`${style.arrows} ${style.arrowPosition}`}>
              {media.length > 1 && (
                <FaChevronLeft
                  className={
                    mediaCount > 1
                      ? `${style.rightActive1} ${style.leftBtn}`
                      : `${style.arrowBtn} ${style.leftBtn}`
                  }
                  onClick={() => {
                    if (mediaCount > 1) {
                      setMediaCount(mediaCount - 1);
                    }
                  }}
                />
              )}

              {media.length > 1 && (
                <FaChevronRight
                  className={
                    mediaCount < media.length
                      ? `${style.rightActive1} ${style.rightBtn}`
                      : `${style.arrowBtn} ${style.rightBtn}`
                  }
                  onClick={() => {
                    if (mediaCount < media.length) {
                      setMediaCount(mediaCount + 1);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={style.storyPath}>
        <div className={style.sectionAlignment}>
          <p className={style.headerText}>Career Pathway</p>
          <div className={style.arrows}>
            {total.length > 1 && (
              <FaChevronLeft
                className={
                  count > 1
                    ? `${style.rightActive} ${style.leftBtn}`
                    : `${style.arrowBtn} ${style.leftBtn}`
                }
                onClick={() => {
                  if (count > 1) {
                    setCount(count - 1);
                  }
                }}
              />
            )}

            {total.length > 1 && (
              <FaChevronRight
                className={
                  count < total.length
                    ? `${style.rightActive} ${style.rightBtn}`
                    : `${style.arrowBtn} ${style.rightBtn}`
                }
                onClick={() => {
                  if (count < total.length) {
                    setCount(count + 1);
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className={style.graphContainer}>
          {total && total.length > 0 && (
            <StoryPath
              events={total[count - 1]}
              count={count}
              total={total.length}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Story;
